<template>
  <div>
    <c-card title="순회결과 정보" class="cardClassDetailForm" :collapsed="true">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <c-select
            codeGroupCd="PATROL_RESULT_CD"
            :editable="editable"
            type="edit"
            :disabled="disabled"
            itemText="codeName"
            itemValue="code"
            name="patrolResultCd"
            label="순회결과"
            v-model="patrol.patrolResultCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="2"
            label="순회결과요약"
            name="patrolResultSummary"
            v-model="patrol.patrolResultSummary">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="항목별 결과 목록"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="patrol.patrolResultModelList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        noDataLabel="항목별 결과 추가하세요."
        :hideBottom="true"
        :editable="editable && !disabled"
        rowKey="saiPatrolResultId"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn
              v-if="editable && !disabled"
              :showLoading="false"
              label="추가"
              icon="add"
              @btnClicked="add"
            />
            <c-btn
              v-if="
                editable &&
                !disabled &&
                patrol.patrolResultModelList.length > 0
              "
              :showLoading="false"
              label="제외"
              icon="remove"
              @btnClicked="remove"
            />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from "quasar";
export default {
  name: 'patrol-result',
  props: {
    patrol: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
        patrolResultCd: null,
        patrolResultSummary: '',
        patrolResultModelList: [],
        deletePatrolResultModelList: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          
        ],
        height: "420px",
        data: [],
      },
      saveCall: {
        saveCallData: '',
      },
      getUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // list setting
      this.setHeader();
    },
    setHeader() {
      this.$comm.getComboItems('PATROL_RESULT_CHECK_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'patrolItemName',
            field: 'patrolItemName',
            label: '점검항목',
            align: 'left',
            type: 'textarea',
            style: 'width:40%',
            sortable: false,
          },
          {
            required: true,
            name: 'patrolResultCd',
            field: 'patrolResultCd',
            label: '점검결과',
            setHeader: true,
            align: 'center',
            style: 'width:20%',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
          {
            setHeader: true,
            name: 'patrolContents',
            field: 'patrolContents',
            label: '비고',
            align: 'left',
            type: 'textarea',
            style: 'width:40%',
            sortable: false,
          },
        ]
      });
    },
    add() {
      this.patrol.patrolResultModelList.splice(0, 0, {
        saiPatrolId: this.patrol.saiPatrolId,
        saiPatrolResultId: uid(),
        patrolItemName: '',
        patrolResultCd: 'PRCC000001',
        patrolContents: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    remove() {
      let selectData = this.$refs["table"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "하나 이상 선택하세요.", // 하나 이상 선택하세요.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.patrol.deletePatrolResultModelList) {
            this.patrol.deletePatrolResultModelList = [];
          }
          if (
            this.$_.findIndex(this.patrol.deletePatrolResultModelList, {
              saiPatrolResultId: item.saiPatrolResultId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.patrol.deletePatrolResultModelList.push(item);
          }
          this.patrol.patrolResultModelList = this.$_.reject(
            this.patrol.patrolResultModelList,
            item
          );
        });
      }
    },
  }
};
</script>
